import React from 'react';
import { TitleContainer } from './style';
import styled from 'styled-components';
import SongsList from '../SongsList';
import { BlockSongsList } from '../layout/songsLists';

const BlockContainer = styled.div`
    border: 1px solid #d9b18f;
    border-radius: 8px; 
    padding: 16px;
    min-height: 150px;
`;

export default function SongBlock() {
    return <BlockContainer>
        <TitleContainer style={{ paddingTop: '8px' }}>Свежие разборы песен</TitleContainer>
        <SongsList
            filter={[]}
            sorting={{ field: 'createdAt', order: 'desc' }}
            pagination={{ limit: 3 }}
            render={BlockSongsList}
        />
    </BlockContainer>
}
