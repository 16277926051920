import Link from 'next/link';
import React from 'react';
import PageTitle from '../components/layout/PageTitle';
import { PageContent, SideBar } from '../components/layout/style';
import SongsBlock from '../components/layout/SongsBlock';

export default function Index() {
    return <>
        <PageContent>
            <PageTitle title='Добро пожаловать'/>

            <p>
                Итак, у вас внезапно возникло желание узнать поближе такой необычный инструмент как гусли.
                Здесь мы попытались собрать кое-какие материалы и инструменты, которые могут вам помочь в освоении
                этого замечательного инструмента.
            </p>

            <h4>
                Выбор инструмента
            </h4>

            <p>
                С чего начать?<br/>
                Наверное, с того, чтобы инструментом обзавестись.
                Сейчас есть довольно много гусельных мастерских. Некоторые из них можно найти <Link href='/masters'><a>здесь</a></Link>.
                Список неполный и неокончательный - в сети всегда можно найти что-то другое.
            </p>

            <h4>Обучение</h4>
            <p>
                Освоить азы и сыграть первые мелодии можно буквально в первый день знакомства с инструментом. <br/>
                <Link href='/lessons'><a>Здесь</a></Link> размещены уроки и упражнения.
            </p>

            <h4>Аккорды и табулатуры</h4>
            <p>
                Мы стараемся использовать современные подходы и технологии к любительскому обучению инструмента.
                Для этого мы сделали интерактивный проигрыватель табулатур - схематического представления мелодии на струнном полотне.
                Вот <Link href={'/songs/[song]'} as='/songs/golitba'><a>пример</a></Link>, как это выглядит.
            </p>

            <p>
                Надеемся, наша работа может оказаться кому-то полезной.<br/><br/>
                Удачи!
            </p>
        </PageContent>
        <SideBar>
            <SongsBlock/>
        </SideBar>
    </>
}
