import React from 'react';
import styled from 'styled-components';
import Head from 'next/head';
import { useRouter } from 'next/router';
import appConfig from '../../config/app';

const MainTitle = styled.h4`
    font-family: 'Ruslan Display', cursive;
    font-size: 24px;
    line-height: 24px;
    margin: 0;
    padding: 0;
    color: #46000b;
`;

const SubTitle = styled.h5`
    font-family: 'Arsenal', sans-serif;
    font-size: 18px;
    font-weight: bold;
    padding: 0;
    margin: 0;
`;

const TitleContainer = styled.div`
    padding: 0 0 24px 0;
`;

export default function PageTitle({ title, subtitle, uptitle, metaTitle }) {
    const router = useRouter();

    const renderMeta = () => {
        const postfix = appConfig.meta.titleSuffix;
        title = metaTitle ? metaTitle : title;
        const description = appConfig.meta.description;

        const restData = {};

        Object.keys(appConfig.meta.og).forEach((property) => {
            restData[`og:${property}`] = appConfig.meta.og[property];
        });

        const ogTags = {
            'og:title': title + postfix,
            'og:description': description,
            ...restData,
            'og:url': `${appConfig.meta.og.url}${router.asPath}`,
        };

        return <Head>
            <title>{title + postfix}</title>
            <meta name='description' content={description}/>
            <meta name='keywords' content={appConfig.meta.keywords}/>
            {Object.keys(ogTags).map((key, index) => <meta key={`og:${index}`} name={key} content={ogTags[key]}/>)}
        </Head>;
    };

    return <TitleContainer>
        <MainTitle>{title}</MainTitle>
        {uptitle}
        {subtitle && <SubTitle>{subtitle}</SubTitle>}
        {renderMeta()}
    </TitleContainer>
}
